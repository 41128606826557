<template>
<div>
  <Search></Search>
  <div class="cart">
    <!-- 购物车无商品 -->
    <div class="no-cart" v-if="noShow">
      <div class="n-info">
        <div class="n-img">
          <img src="../../assets/images/noCart.png" alt="#" />
        </div>
        <div class="n-txt">亲，购物车还是空的哟~</div>
        <div class="n-btn" @click="tohome()">继续逛</div>
      </div>
    </div>
    <!-- 购物车商品 -->
    <div class="h-goods" v-else v-loading="loading">
      <div class="cart-thead mt20">

        <div class="cart-checkbox" @click="Oncheckall()">
          <input type="checkbox" class="sefcheckbox" v-model="checkall" />
          <div class="all-txt">全选</div>
        </div>
        <div class="c-goods">商品</div>
        <div class="c-props">规格</div>
        <div class="c-price">单价</div>
        <div class="c-quantity">数量</div>
        <div class="c-sum">小计</div>
        <div class="c-action">操作</div>
      </div>
      <!-- 购物车列表 -->
      <div class="cart-body mt10" v-for="(item, index) in list" :key="index">
        <div class="store-title">
          <!-- 店铺名称 -->
          <div class="s-checkbox" @click="Onsellercheck(index)">
            <input type="checkbox" v-model="item.selcheack" />
          </div>
          <div class="s-name" @click="Onsellercheck(index)">
            <a> {{ item.store_name }} </a>
          </div>
        </div>
        <!-- 商品列表 -->
        <div
          class="goods-list"
          v-for="(goods, id) in item.goodslist"
          :key="id"
          :class="goods.check == 1 ? 'goods-listno' : ''"
        >
          <div class="l-item">
            <div class="l-checkbox" @click="Oncheck(goods.id, goods.check)">
              <input type="checkbox" v-model="goods.check" />
            </div>
            <div class="l-goods">
              <div class="g-img" @click="Oncheck(goods.id, goods.check)">
                <a>
                  <img :src="goods.img" />
                </a>
              </div>
              <div class="g-msg pl10">
                <a @click="togoods(goods.goods_id)">{{ goods.name }}</a>
              </div>
            </div>

            <div class="l-props" v-if="goods.status == 1">
              <div
                class="props-txt pt10"
                v-for="(attr, key) in goods.attr"
                :key="key"
              >
                {{ attr }}
              </div>
            </div>
            <div class="l-props" v-else>商品失效,已删除</div>
            <div class="l-price">￥{{ goods.price }}</div>
            <div class="l-quantity" v-if="goods.status == 1">
              <el-input-number
                @change="goodsnum(goods.id, goods.num)"
                size="small"
                v-model="goods.num"
                :min="1"
                :max="goods.stock"
              >
              </el-input-number>
            </div>
            <div class="l-quantity" v-else></div>
            <div class="l-sum ml20">
              <strong>￥{{ goods.total }}</strong>
            </div>
            <div class="l-ops">
              <div class="o-delete">
                <a @click="Todel(goods.id)">删除</a>
              </div>
              <div class="o-in mt5">
                <a @click="Follow(goods.id)">移入关注</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 结算 -->
      <div class="c-footer mt20">
        <div class="f-all">
          <div class="f-checkbox" @click="Oncheckall()">
            <input type="checkbox" class="sef-checkbox" v-model="checkall" />
            <div class="f-txt">全选</div>
          </div>
          <div class="operation">
            <a class="o-in" @click="FollowAll()" v-if="totalnum > 0"
              >移入关注</a
            >
            <a class="o-in ml10" @click="Delall()" v-if="totalnum > 0"
              >删除选中的商品</a
            >
            <a class="o-in" @click="ClearCart()">清空购物车</a>
          </div>
        </div>
        <div class="f-account">
          <div class="f-select">
            购物车共计<span>{{ goodsnumj }}</span
            >件商品,已选择<span>{{ totalnum }}</span
            >件商品
          </div>
          <div class="f-sum">
            总价:
            <strong>￥{{ totalprice }}</strong>
          </div>
          <div
            :class="totalnum > 0 ? 'go-account' : 'no-account'"
            @click="ToOrder()"
          >
            去结算
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
  import Search from '../../components/search.vue'
  import { ListCart,CartOncheck,CartDel,CartSeller,CartAll,CartNum,CartClear,CartFollow } from '@/api/cart'
  export default {
    components: {
      Search
    },
    name:"shoppingCart",
    data(){
      return{
        num:1, //数量
        list:[],      // 门店商品列表
        loading:false,
        goodsnumj:0,  // 购物车共多少商品
        totalnum:0,   // 已选几件商品
        totalprice:0, // 已选商品价格总和
        checkall:0,   // 是否全选
        noShow:false
      }
    },
    methods: {
      async getlist(){ 
        this.loading = true
        const {data: res} = await ListCart()
        if(res.message=="ok") this.datainfo(res.result);
        this.loading = false
      },
      async Todel(id){ // 删除
        this.loading = true
        const {data: res} = await CartDel({id:[id]})
        if(res.message=="ok") this.datainfo(res.result);
        this.loading = false
      },
      async Delall(){ // 删除选中
        var ids = this.allcheack();
        if(ids.length==0) return false;
        this.loading = true
        const {data: res} = await CartDel({id:ids});
        if(res.message=="ok") this.datainfo(res.result);
        this.loading = false
      },
      async ClearCart(){ // 清空购物车
        this.loading = true
        const {data: res} = await CartClear();
        if(res.message=="ok") this.datainfo(res.result);
        this.loading = false
      },
      async Follow(id){ // 单个移入关注
        this.loading = true
        const {data: res} = await CartFollow({id:[id]});
        if(res.message=="ok") this.datainfo(res.result);
        this.loading = false
      },
      async FollowAll(){  // 选中移入关注(多个)
        var ids = this.allcheack();
        if(ids.length==0) return false;
        this.loading = true
        const {data: res} = await CartFollow({id:ids});
        if(res.message=="ok") this.datainfo(res.result);
        this.loading = false
      },
      async Oncheck(id,cheack){  // 单件商品选中事件
        this.loading = true
        const {data: res} = await CartOncheck({id:id,cheack:cheack})
        if(res.message=="ok") this.datainfo(res.result);
        this.loading = false
      },
      async Onsellercheck(index){ // 门店全选事件
        var data  = {store_id:this.list[index].store_id,cheack:this.list[index].selcheack}
        this.loading = true
        const {data: res} = await CartSeller(data)
        if(res.message=="ok") this.datainfo(res.result);
        this.loading = false
      },
      async Oncheckall(){     // 全选按钮
        this.loading = true
        const {data: res} = await CartAll({type:this.checkall})
        if(res.message=="ok") this.datainfo(res.result);
        this.loading = false
      }, 
      async goodsnum(id,num){ // 商品购买数量变化
        this.loading = true
        const {data: res} = await CartNum({id:id,num:num})
        if(res.message=="ok") this.datainfo(res.result);
        this.loading = false
      },
      allcheack(){  // 收集已选中的ID
        var ids = new Array();
        for(var item in this.list){
          for(var i in this.list[item].goodslist){
            if(this.list[item].goodslist[i].check==1)
            ids.push(this.list[item].goodslist[i].id)
          }
        }
        return ids;
      },
      datainfo(result){
        if(result.list.length==0){this.noShow = true;return true;} 
        this.list       = result.list;
        this.checkall   = result.checkall;
        this.totalnum   = result.totalnum;
        this.goodsnumj  = result.goodsnum;
        this.totalprice = result.totalprice;
      },
      togoods(id){
        let routeUrl = this.$router.resolve({
           path: "/store/goods",
           query: {id:id}
         });
        window.open(routeUrl.href, '_blank');
      },
      tohome(){
        this.$router.push('/home');
      },
      ToOrder(){
        if(this.totalnum<=0) return false;
        this.$router.push('/home/orderConfirm');
      },
    },
    created() {
      this.id = this.$route.query.id;
      this.getlist()
    }
  }
</script>

<style lang="less" scoped>
.cart {
  width: 1200px;
  min-height: 1000px;
  margin: 0 auto;
  box-sizing: border-box;
  color: #666;
  position: relative;
  .no-cart {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    .n-info {
      width: 274px;
      height: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .n-img {
        width: 274px;
        height: 174px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .n-txt {
        margin: 40px 0px;
        font-size: 14px;
      }
      .n-btn {
        width: 120px;
        height: 40px;
        border: 1px solid #282828;
        border-radius: 6px;
        line-height: 40px;
        text-align: center;
        font-size: 14px;
        color: #282828;
        cursor: pointer;
      }
    }
  }
  .h-goods {
    padding: 20px 0px;
    .cart-title {
      height: 38px;
      font-size: 16px;
      color: #e1251b;
      font-weight: 600;
      line-height: 40px;
      span {
        font-size: 14px;
      }
    }
    .cart-thead {
      height: 40px;
      background: #efefef;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .cart-checkbox {
        display: flex;
        width: 132px;
        padding-left: 5px;
        .sefcheckbox {
          vertical-align: middle;
          margin-right: 5px;
        }
        .all-txt {
          margin-top: 1px;
          text-align: center;
          cursor: pointer;
        }
      }
      .c-goods {
        width: 208px;
      }
      .c-props {
        width: 140px;
        padding: 0 10px 0 20px;
      }
      .c-price {
        width: 120px;
      }
      .c-quantity {
        width: 80px;
      }
      .c-sum {
        width: 100px;
        text-align: center;
      }
      .c-action {
        width: 75px;
        padding-right: 40px;
        text-align: center;
      }
    }
    .cart-body {
      .store-title {
        display: flex;
        align-items: center;
        height: 30px;
        padding-left: 5px;
        border-bottom: 1px solid #d3d3d3;
        .s-name {
          font-size: 14px;
          margin-left: 10px;
          max-width: 260px;
          color: #d69977;
          font-weight: 700;
          cursor: pointer;
        }
        .s-name a {
          color: #d69977;
        }
      }
      .goods-listno {
        background: #f9f9f9;
      }
      .goods-list {
        height: 120px;
        padding-top: 10px;
        padding-left: 5px;
        border: 1px solid #d3d3d3;
        border-top: none;
        display: flex;
        .l-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          box-sizing: border-box;
          .l-goods {
            width: 400px;
            display: flex;
            padding-left: 15px;
            .g-img {
              width: 90px;
              height: 90px;
              cursor: pointer;
              img {
                width: 90px;
                height: 90px;
              }
            }
            .g-msg {
              a {
                color: #333;
                cursor: pointer;
              }
              a:hover {
                color: #e1251b;
              }
            }
          }
          .l-props {
            width: 100px;
            padding-left: 15px;
            padding-bottom: 25px;
            .props-txt {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: #a5a5a5;
            }
          }
          .l-price {
            width: 100px;
            text-align: center;
            margin-left: 65px;
            margin-right: 35px;
          }
          .l-quantity {
            width: 150px;
            text-align: center;
          }
          .l-sum {
            width: 150px;
            text-align: center;
            color: #e1251b;
          }
          .l-ops {
            width: 100px;
            text-align: center;
            cursor: pointer;
          }
        }
      }
    }
    .c-footer {
      height: 50px;
      border: 1px solid #d3d3d3;
      box-sizing: border-box;
      display: flex;
      .f-all {
        width: 600px;
        height: 50px;
        display: flex;
        align-items: center;
        .f-checkbox {
          display: flex;
          padding-left: 5px;
          .sef-checkbox {
            vertical-align: middle;
            margin-right: 5px;
          }
          .f-txt {
            margin-top: 1px;
            cursor: pointer;
          }
        }
        .operation {
          .o-in {
            margin: 0px 10px;
            cursor: pointer;
          }
        }
      }
      .f-account {
        width: 600px;
        height: 50px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .f-select {
          span {
            color: #e1251b;
            font-size: 14px;
            font-weight: 600;
            padding: 0px 10px;
          }
        }
        .f-sum {
          margin: 0px 40px;
          strong {
            font-size: 16px;
            color: #e1251b;
            padding-left: 10px;
          }
        }
      }
      .go-account {
        width: 100px;
        height: 50px;
        background: #e1251b;
        text-align: center;
        line-height: 50px;
        color: #fff;
        font-size: 20px;
        font-weight: 700;
        cursor: pointer;
      }
      .no-account {
        width: 100px;
        height: 50px;
        background: #eee;
        text-align: center;
        line-height: 50px;
        color: #fff;
        font-size: 20px;
        font-weight: 700;
      }
    }
  }
}
</style>
<template>
       <div class="search-container">
        <div class="search">
          <div class="search_bar">
            <div class="logo">
              <router-link to="/">
                <img src="https://serfoo.com/static/logo.jpg" alt="" />
              </router-link>
            </div>
            <el-row class="search_input">
              <el-col :span="16">
                <el-input
                  placeholder="摄像头"
                  style="width: 80%"
                  v-model="keywords">
                </el-input>
                <el-button @click="search">搜 索</el-button>
              </el-col>
              <el-col :span="4" class="search_right">
                <el-col>
                  <router-link to="/home/shoppingcart">
                    <i class="fa fa-shopping-cart"></i>
                    <span class="item">我的购物车</span>
                  </router-link>
                </el-col>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
</template>

<script>
export default {
  name: "search",
  data(){
    return{
      keywords:this.keyword
    }
  },
  props: {
    keyword: {
      type:String,
      default:''
    }
  },
  methods: {
    search(){
      this.$router.push('/home/search?key='+this.keywords+'&security='+Math.random());
    },
  },
}
</script>

<style lang="less" scoped>
.search-container {
  background: #fff;
  border-bottom: 1px solid #ddd;

  .search {
    height: 100px;
    width: 1200px;
    margin: 0 auto;
    position: relative;

    .search_bar {
      width: 100%;
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo {
        width: 175px;
        // height: 50px;

        img {
          width: 100%;
          image-rendering: -moz-crisp-edges;
          image-rendering: -o-crisp-edges;
          image-rendering: -webkit-optimize-contrast;
          image-rendering: crisp-edges;
          -ms-interpolation-mode: nearest-neighbor;
          // height: 100%;
        }
      }

      .search_input {
        width: 800px;
        height: 40px;
        margin-top: 17px;
        display: flex;
        justify-content: flex-end;

        /deep/ .el-input__inner {
          border: none;
        }

        .el-button {
          border-radius: 0px;
        }

        .el-input {
          border: 2px solid #e1251b;
          border-right: 0px;
        }

        .el-button {
          color: #fff;
          background: #e1251b;
          border: 3px solid #e1251b;
        }

        a:hover {
          color: #e1251b;
        }
      }

      .search_right {
        height: 45px;
        line-height: 45px;
        text-align: center;
        border: 2px solid #efefef;
        color: #333;
        .item {
          margin-left: 5px;
          font-size: 12px;
          color: #666;
        }

        .i {
          color: #666;
        }

        a:hover {
          span {
            color: #e1251b;
          }

          i {
            color: #e1251b;
          }
        }
      }
    }
  }
}
</style>
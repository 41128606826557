import request from '@/libs/request'

/**
 * 商品加入购物车
 */
export function AddCart(data) {
  return request({
    url: '/cart/add',
    method: 'post',
    data
  })
}

/**
 * 购物车列表
 */
export function ListCart() {
  return request({
    url: '/cart/list',
    method: 'get',
  })
}

/**
 * 购物车单件商品选中切换
 */
export function CartOncheck(data) {
  return request({
    url: '/cart/cartcheck',
    method: 'post',
    data
  })
}

/**
 * 购物车单件商品删除
 */
export function CartDel(data) {
  return request({
    url: '/cart/cartdel',
    method: 'post',
    data
  })
}

/**
 * 购物车门店全选
 */
export function CartSeller(data) {
  return request({
    url: '/cart/cartseller',
    method: 'post',
    data
  })
}

/**
 * 购物车全选
 */
export function CartAll(data) {
  return request({
    url: '/cart/cartall',
    method: 'post',
    data
  })
}

/**
 * 购物车商品数量变化
 */
export function CartNum(data) {
  return request({
    url: '/cart/cartnum',
    method: 'post',
    data
  })
}

/**
 * 清空购物车商品
 */
export function CartClear() {
  return request({
    url: '/cart/cartclear',
    method: 'post',
  })
}

/**
 * 购物车商品移入关注
 */
export function CartFollow(data) {
  return request({
    url: '/cart/cartfollow',
    method: 'post',
    data
  })
}







